@import './_variables.scss';

@import './forms';
@import './usersSidebar';
@import './chat_display';
@import './refresh_button';
@import './login';
@import './logout_button';

html {
	background: $bg_light_grey;
	font-family: sans-serif;
}

body {
	margin: 0;
}

button:focus {
	outline: none;
}
