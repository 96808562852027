@import './_variables';

.note {
	position: absolute;
	top: 50%;
	left: 62.5%;
	transform: translate(-50%, -50%);
	color: $grey;
	opacity: 0.5;
}
.chat_display {
	position: relative;
	margin-left: 25%;
	line-height: 200%;
	// top: 0;
	width: 75%;
	padding: 5%;
	box-sizing: border-box;
	letter-spacing: 0.3px;
	.chat_entry.kokeshi_answer {
		text-align: left;
		width: 80%;
		span.kokeshi_answer {
			background: $bg_grey;
			color: $primary_color;
			position: relative;
			padding: 5px 15px;
			display: inline-block;
			border-radius: 15px;
			margin: 10px 0;
			display: inline-block;
			&:before {
				content: ' ';
				position: absolute;
				width: 0;
				height: 0;
				left: -14px;
				right: auto;
				top: 0px;
				bottom: auto;
				border: 15px solid;
				border-color: $bg_grey transparent transparent transparent;
			}
			.date {
				background: none;
				font-size: 10px;
				position: absolute;
				bottom: -26px;
				right: 0;
				text-align: right;
				display: block;
				padding: 0;
				margin: 0;
				width: 100%;
				padding-right: 14px;
				color: $grey;
				// opacity: 0;
				transition: opacity 0.5s ease;
			}
			&:hover .date {
				opacity: 0.7;
				transition: opacity 0.5s 1s ease;
			}
			&.fallback {
				background: $red;
				color: white;
				&:before {
					border-color: $red transparent transparent transparent;
				}
			}
		}
	}

	.user_query {
		text-align: right;
		span {
			background: $primary_color;
			color: white;
			position: relative;
			padding: 5px 15px;
			display: inline-block;
			border-radius: 15px;
			display: inline-block;
			margin: 10px 0;
			&:before {
				content: ' ';
				position: absolute;
				width: 0;
				height: 0;
				right: -14px;
				left: auto;
				top: 0px;
				bottom: auto;
				border: 15px solid;
				border-color: $primary_color transparent transparent transparent;
			}
		}

		&.fallback {
			background: $red;
			color: white;
			&:before {
				border-color: $red transparent transparent transparent;
			}
		}
	}

	.action {
		background: $primary_color;
		color: white;
		position: relative;
		left: 50%;
		padding: 5px 15px;
		display: inline-block;
		border-radius: 15px;
		transform: translate(-50%, 0);
		margin: 10px 0;
		text-transform: uppercase;
	}
	.date {
		background: $bg_grey;
		color: $grey;
		position: relative;
		left: 50%;
		padding: 5px 15px;
		display: inline-block;
		border-radius: 15px;
		transform: translate(-50%, 0);
		margin: 10px 0;
	}
}
