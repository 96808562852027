@import './_variables';

.UsersSidebar {
	position: fixed;
	left: 0;
	top: 0;
	width: 25%;
	height: 100%;
	background: white;
	border-right: 1px solid grey;
	overflow-y: scroll;
	.user {
		display: block;
		width: 100%;
		padding: 20px;
		box-sizing: border-box;
		text-align: left;
		border-bottom: 1px solid $bg_grey;
		text-decoration: none;
		color: $primary_color;
		font-family: sans-serif;
		transition: background 0.5s ease;
		&:hover {
			background: $bg_light_grey;
		}
		&.active {
			// text-decoration: underline;
			background: $bg_grey;
			// color: white;
		}
	}
}
