@import './_variables';
form {
	input {
		color: $primary_color;
		display: block;
		width: 100%;
		min-width: 300px;
		margin: 10px 0;
		line-height: 150%;
		padding: 12px;
		border-radius: 3px;
		// color: $green;
		border: 1px solid $primary_color;
		font-size: 15px;
		box-sizing: border-box;
		&::placeholder {
			color: $primary_color;
		}
		&:focus {
			outline: none;
			border: 2px solid $primary_color;
			padding: 11px;
			color: $primary_color;
		}
	}

	button {
		color: white;
		background: $primary_color;
		display: block;
		width: 100%;
		margin: 10px 0;
		line-height: 150%;
		padding: 12px;
		border-radius: 3px;
		// color: $green;
		border: 1px solid $primary_color;
		font-size: 15px;
		box-sizing: border-box;
		cursor: pointer;
		&:active {
			color: white;
		}
		&:focus {
			outline: none;
			// 	border: 2px solid $primary_color;
			// 	padding: 11px;
			// 	color: white;
		}
	}

	form label {
		color: $primary_color;
	}
	.error input {
		color: $red;
		border: solid 2px $red;
		padding: 11px;
		&::placeholder {
			color: $red;
		}
		&:focus {
			color: $primary_color;
			border: solid 2px $primary_color;
			padding: 11px;
			&::placeholder {
				color: $primary_color;
			}
		}
	}
}
