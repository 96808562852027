@import './_variables';

.logout_button {
	position: fixed;
	top: 10px;
	right: 10px;
	color: $grey;
	background: $bg_grey;
	display: block;
	// width: 100%;
	margin: 10px 0;
	line-height: 100%;
	padding: 12px;
	border-radius: 7px;
	// color: $green;
	border: 1px solid $bg_grey;
	font-size: 15px;
	box-sizing: border-box;
	cursor: pointer;
	transition: background 0.5s ease, color 0.5s ease, border 0.5s ease;
	margin: 0;
	&:focus {
		outline: none;
	}
	&:hover {
		background: $primary_color;
		color: white;
		border: 1px solid $primary_color;
	}
}
