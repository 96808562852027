@import './_variables';
.login_page {
	background: url('../img/bg.jpg');
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%;
	position: absolute;
	.login {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 30px;
		border-radius: 10px;
		background: $white_trans;
		overflow: hidden;
		h2 {
			font-weight: 100;
			color: $primary_color;
			margin-top: 0;
		}
		form {
			.loading {
				opacity: 0;
				visibility: hidden;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: $white_trans;
				transition: opacity 0.5s ease, visibility 0s 0.5s;
				span {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					color: $primary_color;
				}
			}
			&.loading .loading {
				opacity: 1;
				visibility: visible;
				transition: opacity 0.5s ease, visibility 0s 0s;
			}
			button {
				width: 100%;
				padding: 15px;
			}
		}
	}
}

.align_center {
	text-align: center;
}
